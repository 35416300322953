import { getIn, useFormikContext } from "formik";

const useFormikFieldsInfo = (name) => {
  const { values, errors } = useFormikContext();
  const helperText = getIn(errors, name);
  return {
    value: getIn(values, name),
    error: Boolean(helperText),
    helperText,
  };
};

export default useFormikFieldsInfo;
