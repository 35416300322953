import { Global } from "@emotion/react";

const GlobalStyle = (props) => (
  <Global
    {...props}
    styles={{
      html: {
        height: "100%",
      },
      body: {
        margin: 0,
        height: "100%",
      },

      form: {
        margin: 0,
      },
    }}
  />
);

export default GlobalStyle;
