import React from "react";
import { Formik, useFormikContext } from "formik";

import { Button } from "@mui/material";
import { useAuth } from "../../../../contexts/FirebaseAuthContext";
import useSnackBar from "../../../../ui-shared/components/snackbar";
import schema from "./schema";
import Grid from "@mui/material/Grid";
import FormikFieldsRenderer from "../../../../ui-shared/ui-forms/formik-fields-renderer";
import { FIELDS_CONFIG } from "./config";

const FieldWrapper = (props) => {
  return <Grid item xs={12} {...props} />;
};

const Body = () => {
  const { isSubmitting } = useFormikContext();
  return (
    <>
      <Grid container spacing={2}>
        <FormikFieldsRenderer
          options={FIELDS_CONFIG}
          FieldWrapper={FieldWrapper}
        />
      </Grid>
      <Button
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
        disabled={isSubmitting}
        sx={{ my: 4 }}
      >
        Send
      </Button>
    </>
  );
};

function ResetPassword() {
  const { notifySuccess } = useSnackBar();
  const { resetPassword } = useAuth();
  const { notifyError } = useSnackBar();

  return (
    <Formik
      initialValues={schema.getDefault()}
      validationSchema={schema}
      onSubmit={async (values, { setSubmitting }) => {
        try {
          await resetPassword(values.email);
          notifySuccess("Reset password email send successfully");
        } catch (error) {
          const message = error.message || "Something went wrong";
          notifyError(message);
          setSubmitting(false);
        }
      }}
    >
      {({ handleSubmit }) => (
        <form noValidate onSubmit={handleSubmit}>
          <Body />
        </form>
      )}
    </Formik>
  );
}

export default ResetPassword;
