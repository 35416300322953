import { initializeApp } from "firebase/app";
import { connectFirestoreEmulator, getFirestore } from "firebase/firestore";
import { connectAuthEmulator, getAuth } from "firebase/auth";
import { connectStorageEmulator, getStorage } from "firebase/storage";
import { getRemoteConfig } from "firebase/remote-config";

import { CONFIG, firebaseConfig } from "../config";

const app = initializeApp(firebaseConfig);

const db = getFirestore(app);
const auth = getAuth(app);
const storage = getStorage(app);
const remoteConfig = getRemoteConfig(app);
remoteConfig.settings.minimumFetchIntervalMillis = 5000;

if (CONFIG.isEmulator) {
  connectFirestoreEmulator(db, "localhost", 8080);
  connectAuthEmulator(auth, "http://localhost:9099/");
  connectStorageEmulator(storage, "localhost", 9199);
}

const COLLECTIONS = {
  ENTITIES: "entities",
  FAXES: "faxes",
  FAXES_COUNTS: "faxes-counts",
  PHARMACIES: "pharmacies",
  USERS: "users",
  REPORTS: "reports",
  EXPERITY_CRON_REPORTS: "experity-cron-report",
  CONFIG: "config",
};

const STORAGE_KEYS = {
  GOVERNMENT_ISSUED_IDS: "government-issued-ids",
  PROFILE_IMAGES: "profile-images",
  SIGNATURE_IMAGES: "signature-images",

  WORK_EXCUSE_PDFS: "work-excuse",
  LAB_ORDER_PDFS: "lab-order",
  IMAGING_ORDER_PDFS: "imaging-order",

  DOCTOR_LICENSES: "doctor-licenses",

  ENCOUNTER_PDFS: "encounter-pdfs",

  FAXES: "faxes",
  SIGNATURES: "signatures",
  WORK_EXCUSES: "work-excuse",
};

const SYSTEM_ROLE_KEYS = {
  PATIENT: "Patient",
  DOCTOR: "Doctor",
  SUPPORT: "Support",
  ADMIN: "Admin",
};

export const CHAT_ROLE_KEYS = {
  PATIENT: "user",
  DOCTOR: "moderator",
  SUPPORT: "admin",
  ADMIN: "admin",
};

const SYSTEM_ROLE_TO_CHAT_ROLE_MAP = {
  [SYSTEM_ROLE_KEYS.PATIENT]: CHAT_ROLE_KEYS.PATIENT,
  [SYSTEM_ROLE_KEYS.DOCTOR]: CHAT_ROLE_KEYS.DOCTOR,
  [SYSTEM_ROLE_KEYS.SUPPORT]: CHAT_ROLE_KEYS.SUPPORT,
  [SYSTEM_ROLE_KEYS.ADMIN]: CHAT_ROLE_KEYS.ADMIN,
};

const CHAT_ROLE_TO_SYSTEM_ROLE_MAP = {
  [CHAT_ROLE_KEYS.PATIENT]: SYSTEM_ROLE_KEYS.PATIENT,
  [CHAT_ROLE_KEYS.DOCTOR]: SYSTEM_ROLE_KEYS.DOCTOR,
  [CHAT_ROLE_KEYS.SUPPORT]: SYSTEM_ROLE_KEYS.SUPPORT,
  [CHAT_ROLE_KEYS.ADMIN]: SYSTEM_ROLE_KEYS.ADMIN,
};

const APPOINTMENT_STATUS_KEYS = {
  ALL: "all",
  UNASSIGNED: 0,
  ASSIGNED: 1,
  CLOSED: 2,
  REOPENED: 3,
  REFUND: 4,
};

const PROFILE_SETUP_STATUS_KEYS = {
  COMPLETED: "completed",
};

const ACCOUNT_STATUS_KEYS = {
  ALL: "all",
  ACTIVE: "active",
  SUSPENDED: "suspended",
  SUSPENDED_DUE_TO_CREDENTIALS: "suspended-due-to-credentials",
  SUSPENDED_DUE_TO_PERFORMANCE: "suspended-due-to-performance",
};

export const ACCOUNT_STATUS_KEYS_TO_LABEL_MAP = {
  [ACCOUNT_STATUS_KEYS.ACTIVE]: "Active",
  [ACCOUNT_STATUS_KEYS.SUSPENDED]: "Suspended",
  [ACCOUNT_STATUS_KEYS.SUSPENDED_DUE_TO_PERFORMANCE]: "Performance",
  [ACCOUNT_STATUS_KEYS.SUSPENDED_DUE_TO_CREDENTIALS]: "Credentials",
};

export const PROMPT_KEYS = {
  LAB_ORDERING: "labOrderingPrompt",
  IMAGING_ORDERING: "imagingOrderingPrompt",
  DOCTOR_SUMMARY: "doctorSummaryPrompt",
  DOCTOR_RESPOND: "doctorRespondPrompt",
};

export const PROFILE_STATUS = {
  COMPLETED: "completed",
};

export {
  db,
  auth,
  storage,
  remoteConfig,
  COLLECTIONS,
  STORAGE_KEYS,
  SYSTEM_ROLE_KEYS,
  APPOINTMENT_STATUS_KEYS,
  PROFILE_SETUP_STATUS_KEYS,
  ACCOUNT_STATUS_KEYS,
};
