import React, { useMemo } from "react";
import LineChart from "../../../ui-shared/components/line-chart";
import { useGetEarningDetails } from "../../../services/earnings";
import { groupDateByDate, mergeDatesWithEarnings } from "./transform";
import useLineChartPickerDates from "../../../domain/domain-hooks/use-line-chart-picker-dates";
import Box from "@mui/material/Box";

const CONFIG = [{ name: "Earnings", dataKey: "totalAmount" }];

const EarningsChart = () => {
  const { date, onChangeDate, startDate, endDate, dates } =
    useLineChartPickerDates();
  const { data = [] } = useGetEarningDetails(startDate, endDate);
  const earningsGroupByDate = useMemo(() => groupDateByDate(data), [data]);
  const chartData = useMemo(
    () => mergeDatesWithEarnings(dates, earningsGroupByDate),
    [dates, earningsGroupByDate],
  );

  return (
    <Box mt={4}>
      <LineChart
        title="Personal Information"
        data={chartData}
        monthlyPicker
        date={date}
        onChangeDate={onChangeDate}
        config={CONFIG}
        unit="$"
      />
    </Box>
  );
};

export default EarningsChart;
