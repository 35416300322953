import get from "lodash/get";
import set from "lodash/set";
import { Timestamp } from "firebase/firestore";
import { auth } from "../di/database";

export const withDateHoc = (dataKey) => (item) => {
  const date = get(item, dataKey);
  if (!date?.seconds) {
    return item;
  }
  return set(item, dataKey, new Date(date.seconds * 1000).valueOf());
};

export const withTimestampHoc = (dataKey) => (item) => {
  const date = get(item, dataKey);
  if (date) {
    set(item, dataKey, Timestamp.fromDate(new Date(date)));
  }
  return item;
};
export const getAccessToken = () => `Bearer ${auth?.currentUser?.accessToken}`;

export const getAccessTokenAsync = () =>
  auth?.currentUser
    ?.getIdToken()
    .then((token) => `Bearer ${token}`)
    .catch(() => null);

export const getCurrentUserId = () => auth?.currentUser?.uid;
