export const FORMIK_COMPONENT_KEYS = {
  INPUT_FIELD: "input-field",
  PHONE_FIELD: "phone-field",
  FILE_UPLOAD: "file-upload-field",
  SELECT: "select",
  MULTI_SELECT: "multi-select",
  RADIO_GROUP: "radio-group",
  RADIO_BUTTON_GROUP: "radio-button-group",
  CHECKBOX_GROUP: "checkbox-group",
  CHECKBOX: "checkbox",
  DATE_PICKER: "date-picker",
  SPACER: "spacer",

  CLINIC_AUTOCOMPLETE: "clinic-autocomplete",
  PROVIDER_AUTOCOMPLETE: "provider-autocomplete",
  STATES_AUTOCOMPLETE: "states-autocomplete",
  TIMEZONE_AUTOCOMPLETE: "timezone-autocomplete",
  CERTIFICATION_TYPES_AUTOCOMPLETE: "certification-types-autocomplete",
};
