import { green, grey } from "@mui/material/colors";
import { THEMES } from "../../constants";

const primary = {
  50: "#fef1ed",
  75: "#fac4b6",
  100: "#f8ab97",
  200: "#f5876a",
  300: "#f36e4c",
  400: "#aa4d35",
  500: "#94432e",
};

const secondary = {
  50: "#e9eef6",
  75: "#a6b8d9",
  100: "#829aca",
  200: "#4c6fb3",
  300: "#2751a3",
  400: "#1b3972",
  500: "#183163",
};

const success = {
  50: "#e7f7f0",
  75: "#9cdfc2",
  100: "#73d2a9",
  200: "#36bf84",
  300: "#0db26b",
  400: "#097d4b",
  500: "#086d41",
};

const customOrange = {
  50: "#fff3e0",
  100: "#ffe0b2",
  200: "#ffcc80",
  300: "#ffb74d",
  400: "#ffa726",
  500: "#ff9800",
  600: "#fb8c00",
  700: "#f57c00",
  800: "#ef6c00",
  900: "#e65100",
};

const defaultVariant = {
  name: THEMES.DEFAULT,
  palette: {
    mode: "light",
    primary: {
      main: primary["300"],
      light: primary["50"],
      lighter: primary["50"],
      dark: primary["500"],
      contrastText: "#FFF",
    },
    secondary: {
      main: secondary["300"],
      light: secondary["50"],
      lighter: secondary["50"],
      dark: secondary["500"],
      contrastText: "#FFF",
    },
    success: {
      main: success["300"],
      light: success["50"],
      lighter: success["50"],
      dark: success["500"],
      contrastText: "#FFF",
    },
    grey: {
      main: "#626366",
      light: "#AEB1BB",
      lighter: "#AEB1BB",
      dark: "#AEB1BB",
      contrastText: "#FFF",
    },
    yellow: {
      main: "#FAFAE1",
      lighter: "#FAFAE1",
      dark: "#FAFAE1",
      contrastText: "#FFF",
    },
    bg: {
      main: "#F8F9FB",
      contrastText: "#FFF",
    },
    border: {
      main: "#E6E8EB",
    },
    background: {
      default: "#FFF",
      paper: "#FFF",
    },
  },
  header: {
    color: grey[500],
    background: "#FFF",
    search: {
      color: grey[800],
    },
    indicator: {
      background: customOrange[600],
    },
  },
  footer: {
    color: grey[500],
    background: "#FFF",
  },
  sidebar: {
    color: grey[200],
    background: "#2751A3",
    header: {
      color: grey[200],
      background: "#2751A3",
      brand: {
        color: customOrange[500],
      },
    },
    footer: {
      color: grey[200],
      background: "#2751A3",
      online: {
        background: green[500],
      },
    },
    badge: {
      color: "#FFF",
      background: customOrange[500],
    },
  },
};

const variants = [defaultVariant];
export default variants;
