import styled from "@emotion/styled";
import BaseBackdrop from "@mui/material/Backdrop";
import { CircularProgress } from "@mui/material";

const Backdrop = styled(BaseBackdrop)`
  opacity: 0.5 !important;
  z-index: 9999;
`;

export const OverlayLoader = () => {
  return (
    <Backdrop open={true}>
      <CircularProgress />
    </Backdrop>
  );
};

export default OverlayLoader;
