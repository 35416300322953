import React, { Fragment, memo, useCallback } from "react";
import { useFormikContext } from "formik";
import xor from "lodash/xor";
import FormLabel from "@mui/material/FormLabel";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import useFormikFieldsInfo from "./hooks/use-formik-fields-Info";

const DefaultContainer = (props) => <Fragment {...props} />;
const DefaultItemContainer = (props) => <Fragment {...props} />;

const FormikCheckboxesGroupBody = ({
  label,
  value,
  required,
  error,
  helperText,
  options,
  onChange,
  Container = DefaultContainer,
  ItemContainer = DefaultItemContainer,
}) => {
  return (
    <FormControl
      component="fieldset"
      variant="standard"
      error={error}
      required={required}
      fullWidth
    >
      <FormLabel component="legend">{label}</FormLabel>
      <FormGroup>
        <Container>
          {options.map((item) => {
            const checked = value?.includes(item.value);
            return (
              <ItemContainer key={item.value}>
                <FormControlLabel
                  label={item.label}
                  name={item.name}
                  value={item.value}
                  onChange={onChange}
                  control={<Checkbox checked={checked} />}
                />
              </ItemContainer>
            );
          })}
        </Container>
      </FormGroup>
      {!!helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};

const MemoizedFormikCheckboxesGroupBody = memo(FormikCheckboxesGroupBody);

const FormikCheckboxesGroup = ({
  name,
  label,
  required,
  options = [],
  Container,
  ItemContainer,
}) => {
  const { setFieldValue } = useFormikContext();
  const { value, error, helperText } = useFormikFieldsInfo(name);
  const onChange = useCallback(
    (event) => {
      setFieldValue(name, xor(value || [], [event.target.value]), true);
    },
    [name, setFieldValue, value],
  );
  return (
    <MemoizedFormikCheckboxesGroupBody
      label={label}
      value={value}
      required={required}
      error={error}
      helperText={helperText}
      options={options}
      onChange={onChange}
      Container={Container}
      ItemContainer={ItemContainer}
    />
  );
};

export default FormikCheckboxesGroup;
