import React, { useCallback, useMemo } from "react";
import FormHelperText from "@mui/material/FormHelperText";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import keyBy from "lodash/keyBy";

import useFormikFieldsInfo from "./hooks/use-formik-fields-Info";
import useFormikCacheValue from "./hooks/use-formik-cache-value";

const FormikMultiSelect = ({
  name,
  label,
  required,
  options = [],
  useCache,
}) => {
  const { value, error, helperText } = useFormikFieldsInfo(name);
  const { cacheValue, onBlur, onChange } = useFormikCacheValue(
    name,
    value,
    useCache,
  );
  const optionsMap = useMemo(() => {
    return keyBy(options, "value");
  }, [options]);

  const getSelectValues = useCallback(
    (selected) => (
      <Box display="flex" flexWrap="wrap" gap={0.5}>
        {selected.map((id) => {
          const { label, value } = optionsMap[id];
          return <Chip key={value} label={label} />;
        })}
      </Box>
    ),
    [optionsMap],
  );

  return (
    <FormControl fullWidth required={required} error={error}>
      <InputLabel>{label}</InputLabel>
      <Select
        multiple
        onClose={onBlur}
        value={cacheValue}
        onChange={onChange}
        renderValue={getSelectValues}
        input={<OutlinedInput label={label} name={name} onBlur={onBlur} />}
      >
        {options.map(({ label, value }) => (
          <MenuItem key={value} value={value}>
            <Checkbox checked={cacheValue.indexOf(value) > -1} />
            <ListItemText primary={label} />
          </MenuItem>
        ))}
      </Select>
      {!!helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};

export default FormikMultiSelect;
