import React, { memo, useCallback } from "react";
import { useFormikContext } from "formik";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import useFormikFieldsInfo from "./hooks/use-formik-fields-Info";

const FormikCheckboxBody = ({
  label,
  value,
  required,
  error,
  helperText,
  onChange,
}) => {
  return (
    <FormControl
      component="fieldset"
      variant="standard"
      error={error}
      required={required}
    >
      <FormGroup>
        <FormControlLabel
          label={label}
          onChange={onChange}
          control={<Checkbox checked={value} />}
        />
      </FormGroup>
      {!!helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};

const MemoizedFormikCheckboxBody = memo(FormikCheckboxBody);

const FormikCheckbox = ({ name, label, required }) => {
  const { setFieldValue } = useFormikContext();
  const { value, error, helperText } = useFormikFieldsInfo(name);
  const onChange = useCallback(
    (event) => {
      setFieldValue(name, event.target.checked, true);
    },
    [name, setFieldValue],
  );
  return (
    <MemoizedFormikCheckboxBody
      label={label}
      value={value}
      required={required}
      error={error}
      helperText={helperText}
      onChange={onChange}
    />
  );
};

export default FormikCheckbox;
