import compact from "lodash/compact";
import join from "lodash/join";

export const toName = ({ suffix, firstName, middleName, lastName } = {}) =>
  compact([
    firstName,
    middleName,
    lastName,
    suffix === "none" ? "" : suffix,
  ]).join(" ");
export const toAddress = ({ address, city, state, zip } = {}) => {
  if (!address && !city && !state && !zip) {
    return "";
  }
  return `${address || ""} ${city || ""}, ${state || ""} ${zip || ""}`;
};
export const transformEntityToLabels = (items = []) => {
  const valueToLabelMap = {};
  items.forEach((item) => {
    valueToLabelMap[item.value] = item.label;
  });

  valueToLabelMap["no"] = "Denies";
  valueToLabelMap["none"] = "Denies";
  return valueToLabelMap;
};
export const valuesToLabelsList = (values, entityItems = []) => {
  const valueToLabelMap = transformEntityToLabels(entityItems);
  const labels = [];
  values.forEach((value) => {
    labels.push(valueToLabelMap[value]);
  });
  return join(labels, ", ");
};
export const valueToLabelMap = (value, entityItems = []) => {
  const valueToLabelMap = transformEntityToLabels(entityItems);
  return valueToLabelMap[value];
};
