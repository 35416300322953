import React from "react";
import { useRoutes } from "react-router-dom";
import { CacheProvider } from "@emotion/react";

import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import { SnackbarProvider } from "notistack";
import createTheme from "./ui-shared/theme";
import { buildRoutes } from "./routes";

import useTheme from "./ui-shared/hooks/use-theme";
import { AuthProvider } from "./contexts/FirebaseAuthContext";
import useRoles from "./domain/domain-hooks/use-roles";
import createCache from "@emotion/cache";

const clientSideEmotionCache = createCache({ key: "css" });

const Body = () => {
  const { isDoctor, isPatient } = useRoles();
  const routes = buildRoutes({ isDoctor, isPatient });
  return useRoutes(routes);
};

function App({ emotionCache = clientSideEmotionCache }) {
  const { theme } = useTheme();
  return (
    <CacheProvider value={emotionCache}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <MuiThemeProvider theme={createTheme(theme)}>
          <SnackbarProvider maxSnack={3} autoHideDuration={3000}>
            <AuthProvider>
              <Body />
            </AuthProvider>
          </SnackbarProvider>
        </MuiThemeProvider>
      </LocalizationProvider>
    </CacheProvider>
  );
}

export default App;
