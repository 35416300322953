import React from "react";

import Avatar from "@mui/material/Avatar";
import { useAuth } from "../../contexts/FirebaseAuthContext";
import { useGetMe } from "../../services/user";
import SidebarNavListItem from "./SidebarNavListItem";
import useUserName from "../../domain/domain-hooks/use-user-name";
import { CircularProgress } from "@mui/material";
import Box from "@mui/material/Box";
import { ReactComponent as SupportIcon } from "./icons/support.svg";
import useRoles from "../../domain/domain-hooks/use-roles";

const SidebarFooter = () => {
  const { user } = useAuth();
  const { data, isLoading } = useGetMe();
  const { isPatient } = useRoles();
  const username = useUserName();
  const profileImage = data?.profileImage;

  if (isLoading) {
    return (
      <Box p="12px">
        <CircularProgress size={16} />
      </Box>
    );
  }
  return (
    <>
      {isPatient && (
        <SidebarNavListItem
          href="/support"
          icon={SupportIcon}
          title="Support"
        />
      )}
      <SidebarNavListItem href="/settings" title={username}>
        <Avatar
          sx={{ width: 24, height: 24 }}
          alt={user.displayName}
          src={profileImage}
        ></Avatar>
      </SidebarNavListItem>
    </>
  );
};

export default SidebarFooter;
