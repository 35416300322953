import { useQuery } from "@tanstack/react-query";
import apis from "../apis";
import { EARNING_CACHE_KEYS } from "./cache-keys";

export const useGetEarnings = () => {
  return useQuery({
    queryKey: [EARNING_CACHE_KEYS.EARNINGS_SUMMARY],
    queryFn: () =>
      apis.getEarnings({}).then((response) => response?.data?.data || {}),
    refetchOnWindowFocus: false,
  });
};

export const useGetEarningDetails = (startDate, endDate) => {
  return useQuery({
    queryKey: [EARNING_CACHE_KEYS.EARNINGS_DETAILS, { startDate, endDate }],
    queryFn: () =>
      apis
        .getEarningDetails({
          startDate,
          endDate,
        })
        .then((response) => response?.data?.data || {}),
    refetchOnWindowFocus: false,
  });
};
