import React, { useCallback, useState } from "react";
import {
  Area,
  AreaChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import styled from "@emotion/styled";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { DatePicker } from "@mui/x-date-pickers";
import map from "lodash/map";
import size from "lodash/size";
import forEach from "lodash/forEach";

const ChartSection = styled(Box)`
  font-size: 12px;
  border: 1px solid ${(props) => props.theme.palette.border.main};

  .xAxis {
    line {
      stroke: #e6e8eb !important;
    }
  }

  .yAxis {
    line {
      display: none;
    }
  }

  .recharts-cartesian-axis-tick {
    line {
      display: none;
    }

    text {
      fill: #626366 !important;
    }
  }
`;

// const data = [
//   { name: "Jan", value: 40},
//   { name: "Feb", value: 30},
//   { name: "Mar", value: 20},
//   { name: "Apr", value: 27},
//   { name: "May", value: 18},
//   { name: "June",value: 23},
//   { name: "July",value: 34},
//   { name: "Aug", value: 34},
//   { name: "Sep", value: 34},
//   { name: "Oct", value: 34},
//   { name: "Nov", value: 34},
//   { name: "Dec", value: 34},
// ];

const error = console.error;
console.error = (...args) => {
  if (/defaultProps/.test(args[0])) return;
  error(...args);
};

const configToOpacityMap = (config = []) => {
  const dataMap = {};
  forEach(config, (item) => {
    dataMap[item.dataKey] = 1;
  });
  return dataMap;
};

const useActions = (config) => {
  const [hideDataKeys, setHideDataKeys] = useState({});
  const [opacityDataMap, setOpacityDataMap] = React.useState(() =>
    configToOpacityMap(config),
  );

  const handleLegendClick = useCallback(
    ({ dataKey } = {}) =>
      setHideDataKeys((old) => ({
        ...old,
        [dataKey]: !old[dataKey],
      })),
    [],
  );
  const handleMouseEnter = useCallback(
    ({ dataKey } = {}) =>
      setOpacityDataMap((op) => ({
        ...op,
        [dataKey]: 0.5,
      })),
    [],
  );
  const handleMouseLeave = useCallback(
    ({ dataKey } = {}) =>
      setOpacityDataMap((op) => ({
        ...op,
        [dataKey]: 1,
      })),
    [],
  );

  return {
    hideDataKeys,
    opacityDataMap,
    handleLegendClick,
    handleMouseEnter,
    handleMouseLeave,
  };
};

const LineChart = ({
  title,
  data = [],
  monthlyPicker = false,
  date,
  onChangeDate,
  config,
  unit = "",
}) => {
  const {
    hideDataKeys,
    opacityDataMap,
    handleLegendClick,
    handleMouseEnter,
    handleMouseLeave,
  } = useActions(config);

  return (
    <ChartSection
      display="grid"
      gridTemplateRows="auto 1fr"
      gap={4}
      sx={{
        height: { xs: 300, md: 410, lg: 500 },
      }}
      borderRadius={4}
      p={4}
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        width="100%"
      >
        <Typography variant="h6">{title}</Typography>
        {monthlyPicker && (
          <DatePicker
            openTo="month"
            views={["year", "month"]}
            label="Year and Month"
            value={date}
            onChange={onChangeDate}
            sx={{ maxWidth: 300 }}
            slotProps={{ textField: { error: false } }}
          />
        )}
      </Box>
      <ResponsiveContainer width="100%">
        <AreaChart
          data={data}
          margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
        >
          <CartesianGrid horizontal vertical={false} stroke="#E6E8EB" />
          <XAxis dataKey="name" color="green" />
          <YAxis />
          <Tooltip />
          {map(config, (item) => {
            return (
              <Area
                key={item.dataKey}
                type="monotone"
                stroke={item.stroke || "#f36e4c"}
                fill={item.fill || "#F36E4C29"}
                strokeWidth={2}
                name={item.name}
                dataKey={item.dataKey}
                hide={hideDataKeys[item.dataKey]}
                strokeOpacity={opacityDataMap[item.dataKey]}
                unit={unit}
              />
            );
          })}
          {size(config) > 1 && (
            <Legend
              onClick={handleLegendClick}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            />
          )}
        </AreaChart>
      </ResponsiveContainer>
    </ChartSection>
  );
};

export default LineChart;
