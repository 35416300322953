import React from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";

import { Chip, ListItemButton, ListItemText } from "@mui/material";

const Item = styled(ListItemButton)`
  padding: 12px;
  color: black;
  border-radius: 8px;
  margin-bottom: 4px;

  svg {
    color: black;
    font-size: 20px;
    width: 20px;
    height: 20px;
  }

  &:hover {
    background: #fef1ed;
  }

  &.${(props) => props.activeclassname} {
    background-color: ${(props) => props.theme.palette.primary.main};

    svg,
    span {
      color: white;
    }
  }
`;

const Title = styled(ListItemText)`
  margin: 0;

  span {
    font-size: ${(props) => props.theme.typography.body1.fontSize}px;
    padding: 0 ${(props) => props.theme.spacing(4)};
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const Badge = styled(Chip)`
  font-weight: ${(props) => props.theme.typography.fontWeightBold};
  height: 20px;
  position: absolute;
  right: 26px;
  top: 12px;
  background: ${(props) => props.theme.sidebar.badge.background};
  z-index: 1;

  span.MuiChip-label,
  span.MuiChip-label:hover {
    font-size: 11px;
    cursor: pointer;
    color: ${(props) => props.theme.sidebar.badge.color};
    padding-left: ${(props) => props.theme.spacing(2)};
    padding-right: ${(props) => props.theme.spacing(2)};
  }
`;

const SidebarNavListItem = (props) => {
  const { title, href, depth = 0, icon: Icon, badge, onClose } = props;

  return (
    <Item
      depth={depth}
      component={NavLink}
      to={href}
      activeclassname="active"
      onClick={onClose}
    >
      {props.children}
      {Icon && <Icon />}
      <Title depth={depth}>
        {title}
        {badge && <Badge label={badge} />}
      </Title>
    </Item>
  );
};

export default SidebarNavListItem;
