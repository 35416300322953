import get from "lodash/get";
import { formatDate } from "../../../utils/time";
import map from "lodash/map";
import forEach from "lodash/forEach";

export const groupDateByDate = (data) => {
  const dataMap = {};
  forEach(data, (item) => {
    const dateKey = formatDate(item.completionDate, "yyyy-dd-MM");
    const oldItem = get(dataMap, dateKey, {});
    dataMap[dateKey] = {
      ...oldItem,
      encounterCount: (item.encounterCount || 0) + 1,
      totalAmount: (oldItem.totalAmount || 0) + item.amount,
    };
  });
  return dataMap;
};

export const mergeDatesWithEarnings = (dates, earningsGroupByDate) => {
  return map(dates, (item) => {
    const { date, dayMonth } = item || {};
    const { encounterCount, totalAmount } = earningsGroupByDate[date] || {};
    return {
      name: dayMonth,
      encounterCount: encounterCount || 0,
      totalAmount: totalAmount || 0,
    };
  });
};
