import React, { memo, useMemo } from "react";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";
import usePasswordToggle from "./use-password-toggle";
import useFormikFieldsInfo from "../hooks/use-formik-fields-Info";
import useFormikCacheValue from "../hooks/use-formik-cache-value";
import MuiTextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

const TextField = styled(MuiTextField)(spacing);

const TextInputFieldBody = (props) => {
  const InputProps = useMemo(() => {
    if (!props.multiline) {
      return props.InputProps;
    }
    return {
      ...props.InputProps,
      rows: props.rows,
      multiline: true,
      inputComponent: "textarea",
    };
  }, [props.InputProps, props.multiline, props.rows]);
  return <TextField fullWidth {...props} InputProps={InputProps} />;
};

const MemoizedTextInputFieldBody = memo(TextInputFieldBody);

const FormikInputField = ({
  type: initialType,
  name,
  label,
  placeholder,
  required,
  useCache,
  multiline,
  rows,
  size,
}) => {
  const { type, InputProps } = usePasswordToggle(initialType);
  const { value, error, helperText } = useFormikFieldsInfo(name);
  const { cacheValue, onBlur, onChange } = useFormikCacheValue(
    name,
    value,
    useCache,
  );

  return (
    <FormControl fullWidth>
      <FormLabel>{label}</FormLabel>
      <MemoizedTextInputFieldBody
        type={type}
        name={name}
        placeholder={placeholder}
        required={required}
        value={cacheValue}
        error={error}
        helperText={helperText}
        onBlur={onBlur}
        onChange={onChange}
        multiline={multiline}
        rows={rows}
        InputProps={InputProps}
        size={size}
      />
    </FormControl>
  );
};

export default FormikInputField;
