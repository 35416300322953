import isValid from "date-fns/isValid";
import differenceInYears from "date-fns/differenceInYears";
import dateFnsFormat from "date-fns/format";

export function calculateAgeFromDob(dateOfBirth) {
  try {
    if (!dateOfBirth) {
      return 0;
    }
    const dob = new Date(dateOfBirth);
    const age = differenceInYears(new Date(), dob);
    return age < 0 ? 0 : age;
  } catch (e) {
    return 0;
  }
}

export const formatDate = (date, format = "MM/dd/yyyy") => {
  if (!date) {
    return "";
  }

  date = new Date(date);
  return dateFnsFormat(date, format);
};

export const formatTimestamp = (date, format = "MM/dd/yyyy") => {
  if (!date) {
    return "";
  }
  return dateFnsFormat(new Date(date), format);
};

export const generateDateAndDay = (date) => {
  return {
    date: formatDate(date, "MM/dd/yyyy"),
    day: formatDate(date, "EEEE"),
    monthYear: formatDate(date, "MM-yyyy"),
  };
};

export const generateDatesInRange = (start, end) => {
  const startDate = new Date(start);
  const endDate = new Date(end);
  const currentDate = new Date(startDate);
  const dates = [];
  while (currentDate <= endDate) {
    dates.push(generateDateAndDay(currentDate));
    currentDate.setDate(currentDate.getDate() + 1);
  }
  return dates;
};

export const generateMonthDates = (start, end) => {
  const startDate = new Date(start);
  const endDate = new Date(end);
  const currentDate = new Date(startDate);
  const dates = [];
  while (currentDate <= endDate) {
    dates.push({
      date: formatDate(currentDate, "yyyy-dd-MM"),
      dayMonth: formatDate(currentDate, "dd MMM"),
    });
    currentDate.setDate(currentDate.getDate() + 1);
  }
  return dates;
};

export const sleeper = (t) => new Promise((s) => setTimeout(s, t));
export const checkIsValidDateString = (dateString) => {
  if (!dateString) {
    return false;
  }
  const date = new Date(dateString);
  return isValid(date);
};

export const isValidDate = (date) => {
  if (!date) {
    return false;
  }
  return date instanceof Date && !Number.isNaN(date.getTime());
};
