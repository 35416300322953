import React from "react";
import Alert from "@mui/material/Alert";
import Page from "../../../ui-shared/components/page";
import PageTitle from "../../../ui-shared/components/page-title";
import EarningsChart from "./earnings-chart";
import EarningSummary from "./earning-summary";

const EarningPage = () => {
  return (
    <Page>
      <PageTitle>Earnings</PageTitle>
      <Alert severity="success">
        Please note that all deposits are processed on the 10th of each month
      </Alert>
      <EarningSummary />
      <EarningsChart />
    </Page>
  );
};

export default EarningPage;
