import React from "react";
import styled from "@emotion/styled";
import { Link } from "react-router-dom";

import MuiDrawer from "@mui/material/Drawer";

import Footer from "./SidebarFooter";
import SidebarNav from "./SidebarNav";
import Box from "@mui/material/Box";

const Drawer = styled(MuiDrawer)`
  border-right: 1px solid #e6e8eb;
  background-color: transparent;

  > div {
    box-shadow: none;
  }
`;

const Logo = styled.img`
  width: 150px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  border-radius: 0;
  box-shadow: none;
  img {
    object-fit: contain;
  }
`;

const Sidebar = ({ items, ...rest }) => {
  return (
    <Drawer variant="permanent" {...rest}>
      <Box p={4} display="grid" gridTemplateRows="auto 1fr auto" height="100%">
        <Box py={4} mb={2}>
          <Link to="/dashboard">
            <Logo alt="" src="/static/img/logo.svg" />
          </Link>
        </Box>
        <SidebarNav items={items} onClose={rest.onClose} />
        <Footer />
      </Box>
    </Drawer>
  );
};

export default Sidebar;
