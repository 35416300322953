import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useReducer,
} from "react";
import {
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signOut,
  sendPasswordResetEmail,
} from "firebase/auth";
import noop from "lodash/noop";
import { auth } from "../di/database";
import { useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";

const INITIALIZE = "INITIALIZE";
const UPDATE_USER = "UPDATE_USER";

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

const reducer = (state, action) => {
  if (action.type === INITIALIZE) {
    const { isAuthenticated, user } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
    };
  }

  if (action.type === UPDATE_USER) {
    const { user } = action.payload;
    return {
      ...state,
      user: { ...state.user, ...user },
    };
  }

  return state;
};

const AuthContext = createContext(null);

function AuthProvider({ children }) {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [state, dispatch] = useReducer(reducer, initialState);
  useEffect(() => {
    onAuthStateChanged(auth, async (authUser) => {
      if (!authUser?.uid) {
        dispatch({
          type: INITIALIZE,
          payload: { isAuthenticated: false, user: null },
        });
      } else {
        const idTokenResult = await authUser.getIdTokenResult();
        dispatch({
          type: INITIALIZE,
          payload: {
            isAuthenticated: true,
            user: {
              id: authUser.uid,
              role: idTokenResult.claims.role,
            },
          },
        });
      }
      // return;
      // const user = await getUser(authUser?.uid);
      // if (isEmpty(user)) {
      //   dispatch({
      //     type: INITIALIZE,
      //     payload: { isAuthenticated: false, user: null },
      //   });
      // } else {
      //   dispatch({
      //     type: INITIALIZE,
      //     payload: { isAuthenticated: true, user },
      //   });
      // }
    });
  }, []);

  const signIn = useCallback(
    async (email, password) => {
      queryClient.removeQueries();
      await signInWithEmailAndPassword(auth, email, password);
      navigate("/dashboard");
    },
    [navigate, queryClient],
  );

  const onSignOut = useCallback(async () => {
    signOut(auth).catch(noop);
    setTimeout(() => {
      queryClient.removeQueries();
    }, 200);
  }, [queryClient]);
  const resetPassword = async (email) => sendPasswordResetEmail(auth, email);

  const onUpdateUserSuccess = useCallback(
    (user) => {
      dispatch({
        type: UPDATE_USER,
        payload: {
          user: {
            ...state.user,
            ...user,
          },
        },
      });
    },
    [state.user],
  );

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: "firebase",
        user: state.user,
        signIn,
        signOut: onSignOut,
        resetPassword,
        onUpdateUserSuccess,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

const useAuth = () => {
  const context = useContext(AuthContext);

  if (!context)
    throw new Error("AuthContext must be placed within AuthProvider");

  return context;
};

export { AuthContext, AuthProvider, useAuth };
