import { useCallback, useEffect, useRef, useState } from "react";
import { useFormikContext } from "formik";
import debounce from "lodash/debounce";

const useFormikCacheValue = (name, value, useCache) => {
  const { handleBlur, setFieldValue, setFieldTouched } = useFormikContext();
  const [cacheValue, setCacheValue] = useState(value);
  const onBlur = useCallback(
    (event) => {
      handleBlur(event);
      if (useCache) {
        setFieldTouched(name, true);
        setFieldValue(name, cacheValue, true);
      }
    },
    [cacheValue, handleBlur, name, setFieldTouched, setFieldValue, useCache],
  );

  const updatedStateWithDebounce = useRef(
    debounce((event) => {
      setFieldValue(name, event.target.value, true);
    }, 1500),
  ).current;

  const onChange = useCallback(
    (event) => {
      if (useCache) {
        setCacheValue(event.target.value);
        updatedStateWithDebounce(event);
      } else {
        setFieldTouched(name, true);
        setFieldValue(name, event.target.value, true);
      }
    },
    [name, setFieldTouched, setFieldValue, updatedStateWithDebounce, useCache],
  );

  useEffect(() => {
    setCacheValue(value);
  }, [value]);

  return {
    cacheValue,
    onBlur,
    onChange,
  };
};

export default useFormikCacheValue;
