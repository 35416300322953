export const firebaseConfig = {
  apiKey: "AIzaSyDKame2fZ3-J6NhEsFJ8V0joVLj6UK3VpU",
  authDomain: "mahaloclinic.firebaseapp.com",
  projectId: "mahaloclinic",
  storageBucket: "mahaloclinic.appspot.com",
  messagingSenderId: "805054441766",
  appId: "1:805054441766:web:1267aacde55de85d74b9e7",
  measurementId: "G-WFCG5YLR5L",
};

const isEmulator = window.location.hostname === "localhost";
export const CONFIG = {
  isEmulator,
  API_URL: isEmulator
    ? "http://127.0.0.1:5001/mahaloclinic/us-central1/api"
    : "https://api-khybjwdmbq-uc.a.run.app",
  stripeApiKey:
    "pk_test_51HYF7QDEiCoGvPfSLOEVX8rVssSMQr3OWovpyOORQcG9SbNsmMwxL6cO7zaYk4yzkzLXwjBBa59UzmJhPHyfw6bB00HKBWCZpc",
  FILE_MAX_SIZE_BYTES: 20 * 1024 * 1024,
  MAP_API_KEY: "AIzaSyDKame2fZ3-J6NhEsFJ8V0joVLj6UK3VpU",

  GET_STREAM_PUBLIC_API_KEY: "zsxq5apnx4qa",

  PUSHER_KEY: "780e068ee2c65c7265ac",
  PUSHER_CLUSTER: "us3",
};

export const AlgoliaConfig = {
  AlgoliaAppID: "FR25WC0UI4",
  AlgoliaSearchApiKey: "8038374e855764081f3d7e54d29b7b47",
  FaxesIndex: "encounters",
};
