import React, { memo, useCallback } from "react";
import { useFormikContext } from "formik";
import FormLabel from "@mui/material/FormLabel";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";

import useFormikFieldsInfo from "./hooks/use-formik-fields-Info";
import Box from "@mui/material/Box";
import { Button } from "@mui/material";

const FormikRadioGroupBody = ({
  label,
  value,
  required,
  error,
  helperText,
  onChange,
  options,
}) => {
  return (
    <FormControl required={required} error={error}>
      <FormLabel>{label}</FormLabel>
      <Box display="flex" gap={4} alignItems="flex-start" flexWrap="wrap">
        {options.map((option) => {
          const active = value === option.value;
          return (
            <Button
              key={option.id}
              variant="outlined"
              color={active ? "primary" : "grey"}
              onClick={() => onChange(option.value)}
            >
              {option.label}
            </Button>
          );
        })}
      </Box>
      {!!helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};

const MemoizedFormikRadioGroupBody = memo(FormikRadioGroupBody);
const FormikRadioButtonGroup = ({
  name,
  label,
  required,
  horizontal,
  options = [],
}) => {
  const { setFieldValue } = useFormikContext();
  const { value, error, helperText } = useFormikFieldsInfo(name);
  const onChange = useCallback(
    (value) => {
      setFieldValue(name, value);
    },
    [name, setFieldValue],
  );

  return (
    <MemoizedFormikRadioGroupBody
      label={label}
      value={value}
      required={required}
      error={error}
      helperText={helperText}
      horizontal={horizontal}
      onChange={onChange}
      options={options}
    />
  );
};

export default FormikRadioButtonGroup;
