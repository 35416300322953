import React, { useCallback, useMemo, useState } from "react";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";

import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";

const usePasswordToggle = (initialType) => {
  const [type, setType] = useState(initialType);
  const toggleType = useCallback(() => {
    setType((type) => {
      if (type === "password") {
        return "text";
      }
      return "password";
    });
  }, []);

  const InputProps = useMemo(() => {
    if (initialType !== "password") {
      return undefined;
    }
    return {
      endAdornment: (
        <InputAdornment position="end">
          <IconButton
            aria-label="toggle password visibility"
            onClick={toggleType}
            onMouseDown={toggleType}
            edge="end"
          >
            {type === "password" ? (
              <RemoveRedEyeOutlinedIcon />
            ) : (
              <VisibilityOffOutlinedIcon />
            )}
          </IconButton>
        </InputAdornment>
      ),
    };
  }, [initialType, toggleType, type]);

  return {
    type,
    toggleType,
    InputProps,
  };
};

export default usePasswordToggle;
