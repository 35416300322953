import React from "react";

import FormikSelect from "./formik-select";
import FormikMultiSelect from "./formik-multiselect";
import FormikInputField from "./formik-input-field";
import FormikRadioGroup from "./formik-radio-group";
import FormikDatePicker from "./formik-date-picker";
import FormikCheckboxesGroup from "./formik-checkboxes-group";
import FormikCheckbox from "./formik-checkbox";
import { FORMIK_COMPONENT_KEYS } from "./constants";
import FormikRadioButtonGroup from "./formik-radio-button-group";

const TYPE_TO_COMPONENT_MAP = {
  [FORMIK_COMPONENT_KEYS.INPUT_FIELD]: FormikInputField,
  [FORMIK_COMPONENT_KEYS.SELECT]: FormikSelect,
  [FORMIK_COMPONENT_KEYS.MULTI_SELECT]: FormikMultiSelect,
  [FORMIK_COMPONENT_KEYS.RADIO_GROUP]: FormikRadioGroup,
  [FORMIK_COMPONENT_KEYS.CHECKBOX_GROUP]: FormikCheckboxesGroup,
  [FORMIK_COMPONENT_KEYS.DATE_PICKER]: FormikDatePicker,
  [FORMIK_COMPONENT_KEYS.CHECKBOX]: FormikCheckbox,
  [FORMIK_COMPONENT_KEYS.RADIO_BUTTON_GROUP]: FormikRadioButtonGroup,
};

const DefaultFieldWrapper = ({ children }) => {
  return <>{children}</>;
};

const FormikFieldsRenderer = ({
  options,
  useCache = false,
  FieldWrapper = DefaultFieldWrapper,
}) => {
  return options.map((item) => {
    if (item.typeKey === FORMIK_COMPONENT_KEYS.SPACER) {
      return <FieldWrapper key={item.name} />;
    }
    const Component = TYPE_TO_COMPONENT_MAP[item.typeKey];
    return (
      <FieldWrapper key={item.name}>
        <Component {...item} useCache={useCache} />
      </FieldWrapper>
    );
  });
};

export default FormikFieldsRenderer;
