import { useGetMe } from "../../services/user";
import { useMemo } from "react";

import { toName } from "../transform";

const useUserName = () => {
  const { data = {} } = useGetMe();
  return useMemo(() => {
    if (data.displayName) {
      return data.displayName;
    }
    return toName(data) || data.email;
  }, [data]);
};

export default useUserName;
