import { useMutation, useQuery } from "@tanstack/react-query";
import apis from "../apis";
import { USER_CACHE_KEYS } from "./cache-keys";

export const useGetMeWithMutation = () => {
  return useMutation({
    mutationFn: () =>
      apis.user.me().then((response) => response?.data?.data || {}),
  });
};

export const useGetMe = () => {
  return useQuery({
    queryKey: [USER_CACHE_KEYS.ME],
    queryFn: () =>
      apis.user.me().then((response) => response?.data?.data ?? {}),
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });
};

export const useGetUser = (id) => {
  return useQuery({
    queryKey: [USER_CACHE_KEYS.USER, { id }],
    queryFn: () =>
      apis.user.get(id).then((response) => response?.data?.data || {}),
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    enabled: Boolean(id),
  });
};

export const useGetUserWithMutation = () => {
  return useMutation({
    mutationFn: (id) =>
      apis.user.get(id).then((response) => response?.data?.data || {}),
  });
};
