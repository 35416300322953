import { FORMIK_COMPONENT_KEYS } from "../../../../ui-shared/ui-forms/constants";

export const FIELDS_CONFIG = [
  {
    typeKey: FORMIK_COMPONENT_KEYS.INPUT_FIELD,
    name: "email",
    type: "email",
    label: "Email",
    required: true,
  },
];
