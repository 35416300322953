import React, { memo, useCallback } from "react";
import { useFormikContext } from "formik";
import Radio from "@mui/material/Radio";
import FormLabel from "@mui/material/FormLabel";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";

import useFormikFieldsInfo from "./hooks/use-formik-fields-Info";

const FormikRadioGroupBody = ({
  name,
  label,
  value,
  required,
  error,
  helperText,
  horizontal,
  onChange,
  options,
}) => {
  return (
    <FormControl required={required} error={error}>
      <FormLabel>{label}</FormLabel>
      <RadioGroup
        name={name}
        value={value}
        row={horizontal}
        onChange={onChange}
      >
        {options.map((option) => (
          <FormControlLabel
            key={option.value}
            value={option.value}
            label={option.label}
            control={<Radio />}
          />
        ))}
      </RadioGroup>
      {!!helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};

const MemoizedFormikRadioGroupBody = memo(FormikRadioGroupBody);
const FormikRadioGroup = ({
  name,
  label,
  required,
  horizontal,
  options = [],
}) => {
  const { setFieldValue } = useFormikContext();
  const { value, error, helperText } = useFormikFieldsInfo(name);
  const onChange = useCallback(
    (event) => {
      setFieldValue(name, event.target.value);
    },
    [name, setFieldValue],
  );

  return (
    <MemoizedFormikRadioGroupBody
      name={name}
      label={label}
      value={value}
      required={required}
      error={error}
      helperText={helperText}
      horizontal={horizontal}
      onChange={onChange}
      options={options}
    />
  );
};

export default FormikRadioGroup;
