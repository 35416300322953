import React, { memo, useMemo } from "react";
import { useFormikContext } from "formik";
import { DatePicker } from "@mui/x-date-pickers";
import useFormikFieldsInfo from "./hooks/use-formik-fields-Info";
import FormLabel from "@mui/material/FormLabel";
import FormControl from "@mui/material/FormControl";
import { checkIsValidDateString } from "../../utils/time";

const FormikDatePickerBody = ({
  name,
  value,
  label,
  required,
  error,
  helperText,
  handleBlur,
  setFieldValue,
}) => {
  const validValue = useMemo(
    () => (checkIsValidDateString(value) ? new Date(value) : null),
    [value],
  );
  return (
    <FormControl fullWidth>
      <FormLabel>{label}</FormLabel>
      <DatePicker
        name={name}
        value={validValue}
        onChange={(value) => {
          setFieldValue(name, value, true);
        }}
        slotProps={{
          textField: {
            fullWidth: true,
            error,
            helperText,
            required,
            onBlur: handleBlur,
          },
        }}
      />
    </FormControl>
  );
};

const MemoizedFormikDatePickerBody = memo(FormikDatePickerBody);

const FormikDatePicker = ({ name, label, required }) => {
  const { handleBlur, setFieldValue } = useFormikContext();
  const { value, error, helperText } = useFormikFieldsInfo(name);
  return (
    <MemoizedFormikDatePickerBody
      name={name}
      value={value}
      label={label}
      required={required}
      error={error}
      helperText={helperText}
      handleBlur={handleBlur}
      setFieldValue={setFieldValue}
    />
  );
};

export default FormikDatePicker;
