import React from "react";
import styled from "@emotion/styled";

import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import ResetPasswordComponent from "./reset-password-form";
import Box from "@mui/material/Box";
import { Link } from "react-router-dom";

const Body = styled(Box)`
  border: 1px solid ${(props) => props.theme.palette.border.main};
`;

const Logo = styled.img`
  height: 35px;
  display: block;
  border-radius: 0;
  margin: auto auto 20px;

  img {
    object-fit: contain;
  }
`;

function ResetPassword() {
  return (
    <Box p={4}>
      <Logo alt="" src="/static/img/logo.svg" />
      <Typography component="h1" variant="h4" align="center" gutterBottom>
        Forgot password
      </Typography>
      <Typography component="h2" variant="body1" align="center" color="grey">
        Enter your login email and we’ll send you a link to reset your password.
      </Typography>
      <Body p={6} pb={2} borderRadius={4} mt={8}>
        <ResetPasswordComponent />
      </Body>
      <Box
        display="flex"
        alignItems="center"
        margin="auto"
        justifyContent="center"
        mt={4}
        gap={2}
      >
        <Typography>Do you remember your password?</Typography>
        <Button
          component={Link}
          to="/auth/sign-in"
          color="primary"
          variant="text"
        >
          Sign in
        </Button>
      </Box>
    </Box>
  );
}

export default ResetPassword;
