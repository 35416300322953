import React from "react";
import styled from "@emotion/styled";

import {
  AppBar as MuiAppBar,
  Avatar,
  IconButton as MuiIconButton,
  Toolbar,
} from "@mui/material";

import { Menu as MenuIcon } from "@mui/icons-material";
import Box from "@mui/material/Box";
import { Link } from "react-router-dom";

const AppBar = styled(MuiAppBar)`
  background: ${(props) => props.theme.header.background};
  color: ${(props) => props.theme.header.color};
`;

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

const Logo = styled.img`
  height: 25px;
  display: block;
  margin: auto;
  img {
    object-fit: contain;
  }
`;

const User = styled(Avatar)`
  width: 32px;
  height: 32px;
  border-radius: 32px;
`;
const Navbar = ({ onDrawerToggle }) => {
  return (
    <AppBar position="sticky" elevation={2}>
      <Box sx={{ display: { xs: "block", md: "none" } }}>
        <Toolbar>
          <Box
            display="grid"
            gridTemplateColumns="repeat(3,1fr)"
            alignItems="center"
            width="100%"
          >
            <IconButton
              color="inherit"
              aria-label="Open drawer"
              onClick={onDrawerToggle}
              size="large"
              sx={{
                justifySelf: "flex-start",
              }}
            >
              <MenuIcon />
            </IconButton>
            <Logo
              alt=""
              src="/static/img/logo.svg"
              sx={{ justifySelf: "center" }}
            />
            <Link
              to="/"
              style={{ justifySelf: "flex-end", textDecoration: "none" }}
            >
              <User alt="" src="">
                U
              </User>
            </Link>
          </Box>
        </Toolbar>
      </Box>
    </AppBar>
  );
};

export default Navbar;
