import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { useMemo } from "react";

const WEB_BREAKPOINT_KEY = "md";
const useDrawerBreakpoints = () => {
  const theme = useTheme();
  const isMdAndUp = useMediaQuery(theme.breakpoints.up("md"));

  const isSmallAndUp = useMediaQuery(theme.breakpoints.up("sm"));

  const isWeb = isMdAndUp;

  const toolbarHeight = useMemo(() => {
    if (isMdAndUp) {
      return "0px";
    }
    return isSmallAndUp ? "64px" : "56px";
  }, [isMdAndUp, isSmallAndUp]);

  return {
    webBreakpointKey: WEB_BREAKPOINT_KEY,
    drawerWidth: 220,
    isWeb,
    sideBarWidth: isWeb ? 220 : 0,
    isMobile: !isWeb,
    toolbarHeight,
  };
};

export default useDrawerBreakpoints;
