import React, { memo } from "react";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";
import { useFormikContext } from "formik";
import { MenuItem, TextField as MuiTextField } from "@mui/material";

import useFormikFieldsInfo from "./hooks/use-formik-fields-Info";
import FormLabel from "@mui/material/FormLabel";
import FormControl from "@mui/material/FormControl";

const TextField = styled(MuiTextField)(spacing);
const FormikSelectBody = ({
  name,
  label,
  required,
  value,
  error,
  helperText,
  handleBlur,
  handleChange,
  options,
}) => {
  return (
    <FormControl fullWidth>
      <FormLabel>{label}</FormLabel>
      <TextField
        select
        fullWidth
        variant="outlined"
        name={name}
        required={required}
        value={value}
        error={error}
        helperText={helperText}
        onBlur={handleBlur}
        onChange={handleChange}
      >
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
    </FormControl>
  );
};

const MemoizedFormikSelectBody = memo(FormikSelectBody);
const FormikSelect = ({ name, label, required, options = [] }) => {
  const { handleBlur, handleChange } = useFormikContext();
  const { value, error, helperText } = useFormikFieldsInfo(name);
  return (
    <MemoizedFormikSelectBody
      name={name}
      label={label}
      required={required}
      value={value}
      error={error}
      helperText={helperText}
      handleBlur={handleBlur}
      handleChange={handleChange}
      options={options}
    />
  );
};

export default FormikSelect;
