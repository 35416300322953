import React from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "../../contexts/FirebaseAuthContext";
import OverlayLoader from "../components/overlay-loader";

function PrivateRouteGuard({ children }) {
  const { isAuthenticated, isInitialized } = useAuth();

  if (!isInitialized) {
    return <OverlayLoader />;
  }

  if (isInitialized && !isAuthenticated) {
    return <Navigate to="/auth/sign-in" />;
  }

  return <>{children}</>;
}

export default PrivateRouteGuard;
